<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ClassroomReservationAvailableSearchPopup v-if="pop.isShow" v-model="pop.info" @close="pop.hideSearch"></ClassroomReservationAvailableSearchPopup>

        <CarrotTitle title="강의장 예약">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="pop.showSearch" class="btn-default">예약 가능 시간 검색하기</button>
            </div>
            - 회사 내부 사용은 회사명을 캐럿솔루션즈/캐럿글로벌/밝음으로 선택해주시기 바랍니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                              <th><span class="txt-red">*</span> 기간</th>
                              <td>
                                <label><input type="radio" v-model="add.dtype" @change="comp.changeDaytype" value="oneday"><span class="ml-5 mr-20"> 하루</span></label>
                                <div class="po-relative dp-inblock w-150px h-32px">
                                  <div v-if="add.dtype=='period'" class="input-lock"></div>
                                  <CarrotDatePicker v-model.sync="add.tdate" @change="comp.changeDate" class="mr-40 inline-block"></CarrotDatePicker>
                                </div>
                                <label><input type="radio" v-model="add.dtype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-20"> 기간</span></label>
                                <div class="po-relative dp-inblock w-300px h-32px">
                                  <div v-if="add.dtype=='oneday'" class="input-lock"></div>
                                  <CarrotDatePicker v-model.sync="add.sdate" class="inline-block"></CarrotDatePicker>
                                  ~
                                  <CarrotDatePicker v-model.sync="add.edate" class="inline-block"></CarrotDatePicker>
                                </div>
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 강의실</th>
                                <td>
                                    <select class="w-200px" v-model="add.idx_classroom">
                                        <option value="">선택</option>
                                        <option :value="irow.idx" v-for="(irow, i) in comp.classroomList" :key="i">{{ irow.cname + ' ' + irow.cnum }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 요일</th>
                                <td>
                                    <div class="po-relative dp-inblock w-400px h-32px">
                                        <div v-if="add.dtype=='oneday'" class="input-lock"></div>
                                        <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="add.days" :value="d"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 시간</th>
                                <td>
                                    <CarrotTime24Select v-model="add.stime"></CarrotTime24Select>
                                     ~ 
                                    <CarrotTime24Select v-model="add.etime"></CarrotTime24Select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 인원</th>
                                <td>
                                    <input type="text" v-model.number="add.count" class="w-100px txt-right">
                                    <span class="ml-5">명</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 과정명 (용도)</th>
                                <td>
                                    <input type="text" v-model.trim="add.title" class="w-100per" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 회사명</th>
                                <td>
                                    <CarrotCompany v-model:companyname.sync="add.company_name" v-model="add.idx_company" class="w-300px"></CarrotCompany>
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 사용 구분(디스플레이용)</th>
                              <td>
                                <label><input type="radio" v-model="add.classtype" value="강의실"><span class="ml-5 mr-20"> In Class(강의중) </span></label>
                                <label><input type="radio" v-model="add.classtype" value="교육장"><span class="ml-5 mr-20"> In Session(세미나중) </span></label>
                                <label><input type="radio" v-model="add.classtype" value="회의실"><span class="ml-5 mr-20"> In Meeting(회의중)</span></label>
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 구분 고객/내부</th>
                              <td>
                                <label><input type="radio" v-model="add.usetype" value="고객용"><span class="ml-5 mr-20"> 고객용 </span></label>
                                <label><input type="radio" v-model="add.usetype" value="내부용"><span class="ml-5 mr-20"> 내부용 </span></label>
                              </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import ClassroomReservationAvailableSearchPopup from '@/components/popup/ResourceManagement/ClassroomReservationAvailableSearchPopup.vue'


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotTime24Select,
        CarrotDatePicker,
        CarrotCompany,
        ClassroomReservationAvailableSearchPopup
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            info : {
                idx    : 0,
                dtype  : "oneday",
                sdate  : "",
                edate  : "",
                sday : "",
                stime  : "07:00",
                etime  : "07:00",
            },

            // Popup 노출 여부
            isShow : false,

            showSearch : () => {
                pop.isShow = true;
            },
            hideSearch : () => {
                pop.isShow = false;

                add.idx_classroom = pop.info.idx;

                add.dtype  = pop.info.dtype;
                add.tdate  = pop.info.tdate;
                add.sdate  = pop.info.sdate;
                add.edate  = pop.info.edate;
                add.days   = pop.info.sday;
                add.stime  = pop.info.stime;
                add.etime  = pop.info.etime;
            },
        });

        const add = reactive({
            idx_classroom : "",
            classtype     : "강의실",
            dtype         : "oneday",
            tdate         : "",
            sdate         : "",
            edate         : "",
            stime         : "07:00",
            etime         : "07:00",
            days          : [],
            title         : "",
            idx_company   : 0,
            company_name  : "",
            usetype:"고객용",
            doCancel: () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    idx_classroom : add.idx_classroom,
                    classtype     : add.classtype,
                    usetype     : add.usetype,
                    sdate         : add.dtype=='oneday'?add.tdate:add.sdate,
                    edate         : add.dtype=='oneday'?add.tdate:add.edate,
                    sday          : add.days,
                    stime         : add.stime,
                    etime         : add.etime,
                    count         : add.count+"",
                    title         : add.title,
                    idx_company   : add.idx_company,
                    company_name  : add.company_name
                };
                if( !params.sdate || !params.edate ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( !params.idx_classroom ){
                    toast.error("강의실을 선택하세요.");
                    return;
                }                
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( params.stime >= params.etime ){
                    toast.error("시간을 확인해주세요.");
                    return;
                }
                if( !params.count || params.count.replace(/[^0-9]/gi, '') != params.count || Number(params.count) <= 0 ){
                    toast.error("입원을 정확히 입력하세요.");
                    return;
                }
                if( !params.title ){
                    toast.error("과정명(용도)을 입력하세요.");
                    return;
                }
                if( !params.idx_company && !params.company_name ){
                    toast.error("회사명을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addClassroomReservation", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '강의장 예약',
                            text : '예약 하였습니다.'
                        }).then(() => {
                            router.push({
                                name : 'ResourceManagement-ClassroomReservationDate'
                            });
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        const comp = reactive({
            classroomList: [],
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],

            getClassroomList: () => {
                let params = {
                    page : 1,
                    rows : 1000
                };
                axios.get('/rest/resourceManagement/getClassroomList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.classroomList = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeDaytype : () => {
                if( add.dtype == 'oneday' ){
                    add.sdate = '';
                    add.edate = '';
                } else {
                    add.tdate = '';
                }
                add.days = [];
            },

            changeDate: () => {
                add.days = [];
                let td = new Date(add.tdate);
                let day = td.getDay();
                if( day == 0 ) add.days.push(comp.dayList[6])
                else add.days.push(comp.dayList[day-1])
            }
        });

        onMounted(() => {
            // Mounted
            comp.getClassroomList();

            let ss = sessionStorage.getItem('ClassroomReservationAdd');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today ){
                    add.tdate = ss.today;
                    comp.changeDate();
                }
                if( ss.time ){
                    add.stime = ss.time;
                    add.etime = ss.time;
                }
                if( ss.idx_classroom ){
                    add.idx_classroom = ss.idx_classroom;
                }
            }
        });

        return {pop, add, comp};
    }
}
</script>

<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>